import React, { useState, useEffect } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default () => {
  const [inputAge, setInputAge] = useState(3)
  const [monthlyRevenue, setmonthlyRevenue] = useState(11000)
  const [monthlyProfit, setmonthlyProfit] = useState(3000)
  const [inputTime, setinputTime] = useState(10)
  const [valuation, setValuation] = useState(0)

  useEffect(() => {
    const newValue = calcValuation()
    if (!isNaN(newValue)) setValuation(Math.trunc(newValue).toLocaleString())
  }, [inputAge, monthlyRevenue, monthlyProfit, inputTime])

  const calcValuation = () => {
    const margin = monthlyProfit / monthlyRevenue
    const multiplierMin = 20
    const multiplierMax = 40
    //normalize inputs
    // N = (input -min)/(max-min)
    const inputAge_N = (inputAge - 1) / (10 - 1)
    const margin_N = (margin - 0) / (80 - 0)
    const inputTime_N = Math.abs(inputTime - 60) / (60 - 0)

    //weights
    const inputAge_W = 0.4
    const margin_W = 0.3
    const inputTime_W = 0.3

    const weightedSum =
      inputAge_W * inputAge_N + margin_W * margin_N + inputTime_W * inputTime_N

    //denormalize
    const multiplier =
      weightedSum * (multiplierMax - multiplierMin) + multiplierMin
    return multiplier * monthlyProfit
  }

  return (
    <Layout>
      <SEO
        title="Funnel Conversion Rate/Profitability Tool"
        description="Understand and see the profitability of each and every step of your funnel."
      />
      <h1 style={{ textAlign: "center" }}>
        Funnel Conversion Rate/Profitability Tool
      </h1>
      <div className="funnel-profit-tool">
        <div className="tool-inputs"> W.I.P</div>
        <div className="tool-results">W.I.P</div>
      </div>
    </Layout>
  )
}
